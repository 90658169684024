import React, { Component } from 'react'
import { ReactComponent as StatsImage } from '../../images/stats.svg';
import './stats.scss'; 

export class Stats extends Component {
  render() {
    return (
      <div className="section stats">
        <div className="col col-1">
          <div className="content">
            <p>2021 m. balandžio 28—gegužės 6 dienomis UAB „Partizanas“ atliko skonio tyrimą, kurio metu siekta išsiaiškinti, kuris degustuojamas nealkoholinis alus dalyviams yra skaniausias.</p>
            <p>Dalyviai ragavo tris Lietuvoje populiariausias nealkoholinio šviesaus alaus rūšis. Tyrimo metu apklaustas 401 nealkoholinį alų geriantis dalyvis nuo 18 iki 61 metų.</p>
            <p>Net 47,9 % apklaustųjų įvertino naująjį „GO Pilsner“ kaip skaniausią pasirinkimą! </p>
            <p className="big">Ar jau ragavai? Įvertink ir tu!</p>
          </div>

        </div>
        <div className="col col-2">
          <div className="stats-image">
            <StatsImage/>
          </div>
        </div>
      </div>
    )
  }
}

export default Stats
