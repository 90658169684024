
import React, { Component } from "react";
import Hero from "./components/Hero/Hero.js";
import Stats from "./components/Stats/Stats.js";
import Product from "./components/Product/Product.js";
import Footer from "./components/Footer/Footer.js";
import Gallery from "./components/Gallery/Gallery.js";
import windowSize from "react-window-size";
import { products } from "./data/data.js";
import GdprSticker from './components/GdprSticker/GdprSticker.js';
//import { debounce, throttle } from "lodash";
import "./App.scss";
import { TweenLite } from "gsap";
//import Modal from './components/Modal/Modal.js';
//import { clearTimeout } from "timers";

export class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal : false,
      height0 : 100,
      height1 : 100,
      // height2 : 100,
      isActive0 : true,
      isActive1 : true,
      // isActive2 : true,
      isParallax : false,
    };
    window.timerScroll = false;
    this.app = React.createRef();
    this.container = React.createRef();
    this.wheelDebounce = 2000;
    this.isWheel = false;
    this.canScroll = true;

    this.prevTime = new Date().getTime();
    this.scrollings = [];

    this.closeModal = this.closeModal.bind(this);
  }

  closeModal(){
    this.setState({openModal:false});
  }

  componentWillMount() {
    
  }
  componentDidMount() {
    this.updateBodyHeight();

    setTimeout(() => {
      this.updateBodyHeight();
    }, 1000);
    // setTimeout(()=>{
    //   this.setState({openModal:true});
    // }, 2000);
    window.addEventListener("resize", this.onResize.bind(this));


    //this.easingSpeed = 0.075;
    this.easingSpeed = 0.075;
    this.paralaxTop = 0;
    this.currentParalaxTop = 0;
    this.currentHeight0 = 0;
    this.currentHeight1 = 0;
    this.timeoutHandler = false;

    this.scrollTop = 0;

    this.onScroll = this.onScroll.bind(this);
    window.addEventListener("scroll", this.onScroll);


    this.onCanScrollEnable = this.onCanScrollEnable.bind(this);
    this.onwheelScroll = this.onwheelScroll.bind(this);
    //window.addEventListener("wheel", debounce( this.onwheelScroll, 2000,  { leading: true, trailing: false } ), {passive:false});
    window.addEventListener("wheel", this.onwheelScroll, { passive: false });

    this.onEnterFrame = this.onEnterFrame.bind(this);
    this.onEnterFrame();
    
  }

  onwheelScroll(e) {
   
    var curTime = new Date().getTime();

    e = e || window.event;
    var value = e.wheelDelta || -e.deltaY || -e.detail;
    var delta = Math.max(-1, Math.min(1, value));
   
    var horizontalDetection = typeof e.wheelDeltaX !== 'undefined' || typeof e.deltaX !== 'undefined';
    var isScrollingVertically = (Math.abs(e.wheelDeltaX) < Math.abs(e.wheelDelta)) || (Math.abs(e.deltaX ) < Math.abs(e.deltaY) || !horizontalDetection);
    

    if (this.scrollings.length > 149) {
      this.scrollings.shift();
    }

    this.scrollings.push(Math.abs(value));

    e.preventDefault();

    //e.preventDefault();

    var timeDiff = curTime - this.prevTime;
    this.prevTime = curTime;
  
    if (timeDiff > 200) {
      this.scrollings = [];
    }
   
    if (this.canScroll) {
     
      this.canScroll = false;

      if( window.timerScroll){
        clearTimeout(window.timerScroll);
        window.timerScroll = false;
      }
      window.timerScroll = setTimeout(this.onCanScrollEnable, 600);
     
      var averageEnd = this.getAverage(this.scrollings, 10);
      var averageMiddle = this.getAverage(this.scrollings, 70);
      var isAccelerating = averageEnd >= averageMiddle;

      let dir = 1;
      if (isAccelerating && isScrollingVertically) {
       
        //scrolling down?
        if (delta < 0) {
          dir = 1;
          //console.log("down");
          //scrolling up?
        } else {
          dir = -1;
          //console.log("up");
        }
       
       
        TweenLite.killTweensOf(document.documentElement);
        TweenLite.to(document.documentElement, 1, {
          scrollTop: document.documentElement.scrollTop + dir * window.innerHeight,
          //ease:Linear.easeNone
        });
        
      }
    }
  }

  onCanScrollEnable(){
  
    this.canScroll = true;
    
  }

  onResize() {
    this.updateBodyHeight();
    this.onScroll(false);
    setTimeout(() => {
      this.updateBodyHeight();
      this.onScroll(false);
    }, 1000);
  }

  updateBodyHeight() {
    this.setState({isParallax : window.innerWidth >= 820 });
    if(window.innerWidth >= 820) {
      this.easingSpeed = 0.075;
      document.body.style.height = this.app.current.clientHeight +  2 * window.innerHeight + "px";
    } else {
      this.easingSpeed = 1;
      document.body.style.height = this.app.current.clientHeight  + "px";;
    }
   
  }

  onEnterFrame() {
    requestAnimationFrame(this.onEnterFrame);

    this.currentParalaxTop +=
      (this.paralaxTop - this.currentParalaxTop) * this.easingSpeed;

      TweenLite.set(this.container.current, {
        transform: `translateY(-${this.currentParalaxTop}px)`
      });
      if( this.state.isParallax ) {
        this.currentHeight0 += (this.scrollTop - this.currentHeight0) * this.easingSpeed ;
        
          let height0 = (3 * window.innerHeight - this.currentHeight0)/ window.innerHeight * 100;
        
          height0 = Math.floor(height0*1.8);
        
          if(height0 >= 99 ) height0 = 100;
          if(height0 <= 1) height0 = 0;

        
          let height1 = (4 * window.innerHeight - this.currentHeight0)/ window.innerHeight * 100;
          height1 = Math.floor(height1*1.8);
          if(height1 >= 99 ) height1 = 100;
          if(height1 <= 1) height1 = 0;

        

          let isActive0 = this.scrollTop >= (window.innerHeight + window.innerHeight * .9) && this.scrollTop < 3 * window.innerHeight ? true : false;
          // let isActive1 = this.scrollTop >= (2 * window.innerHeight + window.innerHeight * .9) && this.scrollTop < 4 * window.innerHeight ? true : false;
          // let isActive2 = this.scrollTop >= (3 * window.innerHeight + window.innerHeight * .9) && this.scrollTop < 5 * window.innerHeight ? true : false;
          this.setState({
            height0 : height0,
            // height1 : height1,
            isActive0 : isActive0,
            // isActive1 : isActive1,
            // isActive2 : isActive2,
          });
      }
  }

  /**
   * Gets the average of the last `number` elements of the given array.
   */
  getAverage(elements, number) {
    var sum = 0;
    //taking `number` elements from the end to make the average, if there are not enought, 1
    var lastElements = elements.slice(Math.max(elements.length - number, 1));

    for (var i = 0; i < lastElements.length; i++) {
      sum = sum + lastElements[i];
    }

    return Math.ceil(sum / number);
  }

  onScroll(e = false) {
   
    if (!this.isWheel) {
    
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      this.scrollTop = this.scrollTop;

      this.paralaxTop = this.scrollTop;
      if( this.state.isParallax) {
        if(this.scrollTop < window.innerHeight) {
     
        } else if( this.scrollTop > 4 * window.innerHeight ) {
         this.paralaxTop =  this.scrollTop - 2 * window.innerHeight;
          
        } else if( this.scrollTop > 2 * window.innerHeight ) {
          this.paralaxTop = 2 * window.innerHeight;
        }
      }
  
    }
  }

  render() {
    var appClass = "App app-fixed";
    var pageWrapperClass = this.state.isParallax ? 'parallax' : '';
    return (
      <div ref={this.app} className={appClass}>
         {/* <Modal onCloseHandler={this.closeModal} open={this.state.openModal}/> */}
        {false && this.props.windowHeight}
        {false &&
        <div className="debug">
          <div className={`canscroll ${this.canScroll ? ' can' : ' no'}`}>{this.canScroll ? 'can' : 'no'}</div>
        </div>
        }
        <div ref={this.container} className="wrapper">
         
          <Hero />
          <Stats/>
          <div className={`page-wrapper ${pageWrapperClass}`}>
            {products.map((item, index) => (
              <Product isParallax={this.state.isParallax} isActive={this.state['isActive'+index] } height={this.state['height'+index]} content={item} index={index} key={index} />
            ))}
           
           
          </div>
          {false && <Gallery />}
          <Footer />
        </div>
        <GdprSticker/>
      </div>
    );
  }
}

export default windowSize(App);
