import React, { Component } from "react";
import Modal from "react-modal";
import { ReactComponent as CloseSVG } from "../../images/close.svg";
import YouTube from '@u-wave/react-youtube';
import "./videoModal.scss";

export default class VideoModal extends Component {
  constructor(props) {
    Modal.setAppElement("#root");
    super(props);

    this.state = {
        modalClass : 'videoModalOverlay',
      modalIsOpen: true,
      YT : false
    };

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this._onReady = this._onReady.bind(this);
    
  }

  _onReady(event) {
      this.setState({ YT : event.target });
  }

  openModal() {
      this.setState({modalClass : "videoModalOverlay opened"});
      this.state.YT.unMute();
      this.state.YT.playVideo();
    //this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = "#f00";
  }

  closeModal() {
    this.setState({modalClass : "videoModalOverlay"});
    this.state.YT.stopVideo();
    //this.setState({ modalIsOpen: false });
  }

  render() {
      let modalClass = this.state.modalClass;
    const youtubeId  = "k2AdcGqyIJI"; 
    return (
      <div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          //style={customStyles}
          contentLabel="Example Modal"
          overlayClassName={modalClass}
          className="videoModalContent"
        >
          <button className="close-btn" onClick={this.closeModal}>
            <CloseSVG />
          </button>
          <div
            className="video"
            style={{
              position: "relative",
              width: "100%",
              height: "100%"
            }}
          >
              <YouTube
                video={youtubeId}
                //autoplay
                muted
                width="100%"
                height="100%"
                showCaptions={false}
                controls={true}
                allowFullscreen={true}
                annotations={false}
                playsInline={true}
                showRelatedVideos={false}
                showInfo={false}
                onReady={this._onReady}
            >
            </YouTube>
          </div>
        </Modal>
      </div>
    );
  }
}
