import React, { Component } from "react";
import YoutubeBackground from "../../YoutubeBackground";
import VideoCover from "react-video-cover";
import "./product.scss";

export class Product extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props
    };
    this._onReady = this._onReady.bind(this);
  }

  _onReady(event) {
    this.YT = event.target;
    this.playVideo();
  }

  playVideo() {
    this.isPlaying = true;
    this.YT.playVideo();
  }

  stopVideo() {
    this.isPlaying = false;
    this.YT.stopVideo();
  }

  render() {
    let productImageStyle = {
      height: window.innerHeight * 0.7
    };

    const videoOptions = {
      loop: true,
      autoPlay: true,
      poster: `assets/images/${this.props.content.video}.png`,
      playsInline: true,
      muted: true,
      src: `assets/media/${this.props.content.video}.mp4`,
      //src: 'http://download.blender.org/peach/bigbuckbunny_movies/BigBuckBunny_320x180.mp4',
      ref: videoRef => {
        this.videoRef = videoRef;
      }
      /*
      onClick: () => {
        if (this.videoRef && this.videoRef.paused) {
          this.videoRef.play();
        } else if (this.videoRef) {
          this.videoRef.pause();
        }
      },
      title: 'click to play/pause',
      */
    };

    productImageStyle.width = productImageStyle.height * 0.42;

    if (window.innerWidth < 820) {
      productImageStyle.height *= 0.7;
      productImageStyle.width *= 0.75;

      productImageStyle.width = window.innerWidth * 0.35618;
      productImageStyle.height *= 2.38;

      //productImageStyle.top = 100;
    }
    let productItemStyle = {};
   
    if(this.props.isParallax) {
      productItemStyle = {
        height : this.props.height + 'vh',
        overflow:'hidden'
      };
    }

    let activeClass = (this.props.isActive || !this.props.isParallax) ? 'active' : 'inactive';
    return (
      <div style={productItemStyle} className={`product-item ${this.props.content.theme} ${activeClass}`}>
        <div className="product-mask">
          <div className="product-wrap">
            <div
              style={productImageStyle}
              className={`product-image product-image-${this.props.index}`}
            />
          </div>
          <div className="row">
            <div className="col col-1">
              <div className="video-wrap">
                <VideoCover videoOptions={videoOptions} />
                {false && (
                  <YoutubeBackground
                    videoId={"1lcdGYKV4Sc"}
                    onReady={this._onReady}
                    className="video-bg"
                  />
                )}
              </div>
            </div>
            <div className="col col-2">
              <div className="content-wrap">
                <div className={`title-wrap ${this.props.content.theme}`}>
                  <div className="title-wrap-inner">
                    <h2>{this.props.content.title}</h2>
                    <span className="subtitle">
                      {this.props.content.subTitle}
                    </span>
                  </div>
                </div>
                <span className="description">
                  {this.props.content.description}
                </span>
                <div className="composition">
                  <div className="composition-group">
                    <div className="composition-row">
                      <span
                        className="header-label"
                        dangerouslySetInnerHTML={{
                          __html: this.props.content.nutritionLabel
                        }}
                      />
                      <span
                        className="header-label"
                        dangerouslySetInnerHTML={{
                          __html: this.props.content.caloriesLabel
                        }}
                      />
                    </div>
                    <div className="composition-row">
                      <span
                        className="header-value"
                        dangerouslySetInnerHTML={{
                          __html: this.props.content.nutritionValue
                        }}
                      />
                      <span
                        className="header-value"
                        dangerouslySetInnerHTML={{
                          __html: this.props.content.caloriesValue
                        }}
                      />
                    </div>
                  </div>
                  {this.props.content.ingredients.map((item, index) => (
                    <div key={index} className="composition-group">
                      {item.map((row, index) => (
                        <div key={index} className="composition-row">
                          <span className="label">{row.label}</span>
                          <span className="netto">{row.value}</span>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Product;
