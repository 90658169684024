import React, { Component } from 'react'
import './footer.scss'
export class Footer extends Component {
  render() {
    return (
        <div className="section footer">
            <div className="bg"></div>
            <div className="cookies-wrap">
              <p><a href="http://www.gonealkoholinis.lt/assets/go-slapuku-pranesimas.pdf"  rel="noopener noreferrer" target="_blank">Salpukų pranešimas</a></p>
            </div>
        </div>
    )
  }
}

export default Footer
