import React, { Component } from 'react'
import Cookies from 'js-cookie';

import './gdprsticker.scss';
export class GdprSticker extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            visible : Cookies.get('privacysticker') ? false : true,
        }

        this.closeStickerButton = this.closeStickerButton.bind(this);
    }

    componentDidMount(){

    }
    
    closeStickerButton(e){
        this.setState({visible : false});
        Cookies.set('privacysticker', 'true', { expires: 7 });
    }
    render() {
        let stickerClass = this.state.visible ? 'show' : '';
        return (
            <div id="gdpr-sticker" className={stickerClass}>
                <p>Ši svetainė yra nustatyta taip, kad „leistų visus slapukus“ siekiant užtikrinti šio puslapio funkcinius parametrus bei pagerintų Jūsų naršymo patirtį. Jei tęsite, nekeisdami šių nustatymų, sutinkate su tuo. Daugiau informacijos – Pranešime apie</p>
                <a href="https://www.gonealkoholinis.lt/assets/go-slapuku-pranesimas.pdf"  rel="noopener noreferrer" target="_blank"> slapukus</a> ir 
                <a href="https://www.gonealkoholinis.lt/assets/go-slapuku-pranesimas.pdf"  rel="noopener noreferrer" target="_blank"> privatumą</a>
            <button  onClick={this.closeStickerButton } className="close"></button>
        </div>
        )
    }
}

export default GdprSticker
