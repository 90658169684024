import React, { Component } from 'react'
import { TweenMax } from 'gsap';

export class GallerySlide extends Component {
    constructor(props) {
        super(props)

        this.transformation = [];
        //this.calcTransforms();

        this.isRight = false;
        this.isRight = false;
        this.isCurrent = false;

        this.isCurrentClass = false;

        this.state = {
            isCurrentClass : false
        }
        this._dom = React.createRef();
    }

    componentDidMount(){
       
        this.transformation = this.props.transformation;
        //console.log(this.transformation);
        this.position(0);
    }

    setRight(value = true) {
        this.isLeft = false;
        this.isRight = value;
        this.position(3);
    }

    isPosRight() {
        return this.isRight;
    }

    setLeft(value = true) {
        this.isRight = false;
        this.isLeft = value;
        this.position(1);
    }

    isPosLeft() {
        return this.isLeft;
    }

    setCurrent(value = true) {
        this.isLeft = false;
        this.isRight = false;
        this.isCurrent = value;

        this.position(2);
    }

    setCurrentClass(value = true){
        this.setState({ isCurrentClass : value });
    }

    isPosCurrent() {
        return this.isCurrent;
    }

    dom(){
        return this._dom.current;
    }

    reset(){
        this.isRight = this.isLeft = this.isCurrent = false;
    }

    position(pos) {
        console.log( this.props.transformation[pos] );
        //console.log(this.props.transforms());
        TweenMax.set(this.dom(), { ...this.props.transformation[pos] } );
    }

    render() {
        let itemClass =  this.state.isCurrentClass ? `${this.props.className} current` : this.props.className;
        return (
            <div style={this.props.style} onClick={this.props.onClick} ref={this._dom} className={itemClass}>
               {this.props.index} 
            </div>
        )
    }
}

export default GallerySlide
