import React, { Component } from 'react'
import YoutubeBackground from '../../YoutubeBackground';
import './hero.scss';
import { ReactComponent as GoLogoSVG } from '../../images/go-logo.svg';
import { ReactComponent as SloganSVG } from '../../images/slogan.svg';
import { ReactComponent as PlayBtnSVG } from '../../images/play-button.svg';

import VideoModal from '../VideoModal/VideoModal'

export class Hero extends Component { 
  constructor(props) {
    super(props)
    this.isPlaying = false; 
    
    this._onReady = this._onReady.bind(this);
    this.onPlayClick = this.onPlayClick.bind(this);

    this.playButtonWrap = React.createRef();
    this.logoWrap = React.createRef();
    this.sloganWrap = React.createRef();

    this.videoModal = React.createRef();

    this.state = {
       
    }
  }
  
  _onReady(event){
    this.YT = event.target;
    this.playVideo();
  }

  playVideo() {
    this.isPlaying = true; 
    this.YT.playVideo();
  }
  
  stopVideo() {
    this.isPlaying = false;
    this.YT.stopVideo();
  }

  /*
  onPlayClick(e = false){
    TweenMax.to(this.logoWrap.current, 0.2, { autoAlpha : 0});
    TweenMax.to(this.playButtonWrap.current, 0.2, { autoAlpha : 0});
    TweenMax.to(this.sloganWrap.current, 0.2, { autoAlpha : 0});
    //TweenLite.to(this.downArrow.current, 0.2, { autoAlpha : 0});
    this.YT.unMute();
  }
  */
onPlayClick(e = false) {
  this.videoModal.current.openModal();
}


  render() {
    return (
      <div className="section hero">
          <div className="bg"></div>
          {/* <YoutubeBackground videoId={"k2AdcGqyIJI"}  onReady={this._onReady} className="video-bg" /> */}
          <VideoModal ref={this.videoModal} />
          {/* <div  ref={this.logoWrap} className="logo">
            <GoLogoSVG/>
          </div>
          <div ref={this.sloganWrap} className="slogan">
            <SloganSVG/>
          </div> */}
          <div ref={this.playButtonWrap} className="play-button">
            <button onClick={this.onPlayClick}><PlayBtnSVG/></button>
            
          </div> 
          <div className="credits"><span>Aklą tyrimą apie tris Lietuvoje populiariausius nealkoholinio alaus skonius</span> 2021 m. balandžio 28—gegužės 6 dienomis<span> atliko UAB „Partizanas“</span> </div>
      </div>
    )
  }
}

export default Hero
