import React, { Component } from "react";
import { TweenMax, Power4 } from "gsap";
import "./gallery.scss";
import { isAbsolute } from "path";
import GallerySlide from "./GallerySlide";
import * as getScrollbarWidth from 'get-scrollbar-width';

export class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [
        'image-1.png',
        'image-2.png',
        'image-3.png',
        'image-1.png',
        'image-2.png',
        'image-3.png',
      ],
      current: 0,
      //transforms : [],
    };
    this.transforms = [];
    this.imgSize = {};
  
    this.galleryWrap = React.createRef();

    this.current = 0;

    this.currentSlide = false;
    this.nextSlide = false;
    this.prevSlide = false;
    this.upcomingSlide = false;

    this.slides = Array(11).fill(0);
    this.slides.map((item, index) => {
      this.slides[index] = React.createRef();
    });
  
    this.onSlideClick = this.onSlideClick.bind(this);
    
    this.totalSlides = this.state.items.length;
    this.calcTransforms();
  }

  updateVisual() {
    this.currentSlide = this.slides[this.current].current;
    this.nextSlide = this.slides[
      this.current + 1 <= this.totalSlides - 1 ? this.current + 1 : 0
    ].current;
    this.prevSlide = this.slides[
      this.current - 1 >= 0 ? this.current - 1 : this.totalSlides - 1
    ].current;

    //console.log(this.currentSlide.dom());
    this.currentSlide.setCurrent();
    this.currentSlide.setCurrentClass(true);
    this.nextSlide.setRight();
    this.prevSlide.setLeft();
  }
  componentDidMount() {
    this.calcTransforms();

    this.onResize = this.onResize.bind(this);
    this.resizeVisualUpdate = this.resizeVisualUpdate.bind(this);
    window.addEventListener('resize', this.onResize)
   
   // console.log(this.slides);
   

    setTimeout(() => {
      this.slides.map((item, index) => {
        //console.log(this.slides[index].current.position(1));
        //this.slides[index].current.position(0);
      });
     
  
     
    }, 2000);
    this.updateVisual();
  }

  onResize(){
   this.resizeVisualUpdate();

    TweenMax.killDelayedCallsTo(this.resizeVisualUpdate);

    TweenMax.delayedCall(0.2, this.resizeVisualUpdate);
  }

  resizeVisualUpdate(){
    this.calcTransforms();
    this.updateVisual();
  }

  calcTransforms() {
    
    /*
    Each position corresponds to the position of a given slide:
    0: left top corner outside the viewport
    1: left top corner (prev slide position)
    2: center (current slide position)
    3: right bottom corner (next slide position)
    4: right bottom corner outside the viewport
    5: left side, for when the content is shown
    */

    let scrollBarWidth = getScrollbarWidth();
    let winsize = {
      width: window.innerWidth - scrollBarWidth, //window.innerWidth,
      height: window.innerHeight
    };
    
    let imgSize = { 
     
      height : window.innerHeight * 0.8
    }
    imgSize.width = imgSize.height * 0.65;

    let scale = 0.75;
    let transforms = [
      {
        x: 0 - (imgSize.width - imgSize.width * scale) * 0.5 - imgSize.width * 2,
        y:
          winsize.height -
          imgSize.height * scale -
          (imgSize.height - imgSize.height * scale) * 0.5 +
          imgSize.height * 2,
        scale: scale
      },
      {
        x: 0 - (imgSize.width - imgSize.width * scale) * 0.5,
        y:
          winsize.height -
          imgSize.height * scale -
          (imgSize.height - imgSize.height * scale) * 0.5,
        scale: scale
      },
      //{x: 0, y: 0, rotation: 0},
      {
        x: winsize.width / 2 - imgSize.width / 2,
        y: winsize.height / 2 - imgSize.height / 2,
        scale: 1
      },

      {
        x:
          winsize.width -
          imgSize.width +
          (imgSize.width - imgSize.width * scale) * 0.5,
        y: 0 - (imgSize.height - imgSize.height * scale) * 0.5,
        scale: scale
      },
      {
        x:
          winsize.width -
          imgSize.width +
          (imgSize.width - imgSize.width * scale) * 0.5 +
          imgSize.width * 2,
        y: 0 - (imgSize.height - imgSize.height * scale) * 0.5 - imgSize.height * 2,
        scale: scale
      }
  
    ];
    this.imgSize = imgSize;
    this.transforms = transforms;
    //console.log(this.transforms);
    //this.setState({ transforms :  transforms, winsize : winsize, imgSize : imgSize, scale : scale });
  }

  onSlideClick(num) {
   
    let slide = this.slides[num].current;

    if (slide.isPosRight()) {
      this.navigate("next");
    } else if (slide.isPosLeft()) {
      this.navigate("prev");
    }
  }

  navigate(direction) {
    //console.log(direction);

    const upcomingPos =
      direction === "next"
        ? this.current < this.totalSlides - 2
          ? this.current + 2
          : Math.abs(this.totalSlides - 2 - this.current)
        : this.current >= 2
        ? this.current - 2
        : Math.abs(this.totalSlides - 2 + this.current);

    //console.log(upcomingPos);
    this.upcomingSlide = this.slides[upcomingPos].current;

    // Update current.
    this.current =
      direction === "next"
        ? this.current < this.totalSlides - 1
          ? this.current + 1
          : 0
        : this.current > 0
        ? this.current - 1
        : this.totalSlides - 1;

    // Move slides (the previous, current, next and upcoming slide).
    this.moveToPosition({
      dom: this.prevSlide.dom(),
      slide: this.prevSlide,
      position: direction === "next" ? -2 : 0,
      delay: direction === "next" ? 0 : 0.14
    }).then(() => {
      if (direction === "next") {
        //this.prevSlide.hide();
      }
    });

    this.moveToPosition({
      dom: this.currentSlide.dom(),
      slide: this.currentSlide,
      position: direction === "next" ? -1 : 1,
      delay: 0.07
    });

    this.moveToPosition({
      dom: this.nextSlide.dom(),
      slide: this.nextSlide,
      position: direction === "next" ? 0 : 2,
      delay: direction === "next" ? 0.14 : 0
    }).then(() => {
      if (direction === "prev") {
        //this.nextSlide.hide();
      }
    });

    this.moveToPosition({
      dom: this.upcomingSlide.dom(),
      slide: this.upcomingSlide,
      position: direction === "next" ? 1 : -1,
      from: direction === "next" ? 2 : -2,
      delay: 0.21
    }).then(() => {
      // Reset classes.
      //console.log("ok");
      [this.nextSlide, this.currentSlide, this.prevSlide].forEach(slide =>
        slide.reset()
      );
      this.updateVisual();

      //this.isAnimating = false;
    });
  }

  moveToPosition(settings) {
    return new Promise((resolve, reject) => {
      /*
        Moves the slide to a specific position:
        -2: left top corner outside the viewport
        -1: left top corner (prev slide position)
        0: center (current slide position)
        1: right bottom corner (next slide position)
        2: right bottom corner outside the viewport
        3: left side, for when the content is shown
        */
      let transforms = this.transforms;
      //console.log(this.transforms);
      if(settings.position + 2 == 2 ) {
        settings.slide.setCurrentClass(true);
      } else {
        settings.slide.setCurrentClass(false);
      }
     
     
      TweenMax.to(settings.dom, 0.8, {
        ease: Power4.easeInOut,
        delay: settings.delay || 0,
        startAt:
          settings.from !== undefined
            ? {
                x: transforms[settings.from + 2].x,
                y: transforms[settings.from + 2].y,
                scale : transforms[settings.from + 2].scale,
              }
            : {},
        x: transforms[settings.position + 2].x,
        y: transforms[settings.position + 2].y,
        scale : transforms[settings.position + 2].scale,
        onStart:
          settings.from !== undefined
            ? () => TweenMax.set(settings.dom, { opacity: 1 })
            : null,
        onComplete: resolve
      });
    });
  }

  setCurrent(slideNum) {}

  setLeft(slideNum) {}

  /*
  getTransforms(){
    return this.transforms;
  }
  */
  render() {
    //this.calcTransforms();
    let gallery = this.state.items;
    let slideStyle = this.imgSize;
    
    return (
      <div ref={this.galleryWrap} className="gallery-wrap">
        <div className="gallery-line-box" />
        <div className="gallery-slides">
          {gallery.map((item, index) => (
            <GallerySlide
              image={item}
              transformation={this.transforms }
              onClick={e => {
                this.onSlideClick(index);
              }} 
              style={{...slideStyle,  backgroundImage : `url(/assets/images/gallery/${item})`}}
              ref={this.slides[index]}
              index={index}
              key={index}
              className={`gallery-item item-${index}`}
            />
          ))}
          ;
        </div>
      </div>
    );
  }
}

export default Gallery;
