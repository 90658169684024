export const products =  [

    {
        title : "PILSNER",
        subTitle : "BE GLITIMO",

        theme : "green",

        video : "bike",

        description : "Lengvas, švelnus skonis ir aromatas. Ypač gaivus",

        nutritionLabel : "Maistingumo<br>deklaracija",
        nutritionValue : "100 ML",

        caloriesLabel : "Energinė<br>vertė",
        caloriesValue : "90 kJ / 21 kcal",

        ingredients : [ 
            [
                {label : "Riebalai" , value : "0 G"},
                {label : "Iš kurių sočiųjų riebalų rūgščių" , value : "0 G"}
            ],
            [
                {label : "Angliavandeniai" , value : "4,3 G"},
                {label : "Iš kurių cukrų" , value : "1,2 G"}
            ],
            [
                {label : "Baltymai" , value : "0,5 G"},
            ],
            [
                {label : "Druska" , value : "0 G"},
            ]
        ]
    },

    // {
    //     title : "PALE ALE",
    //     subTitle : "",

    //     theme : "orange",

    //     video : "car",

    //     description : "Lengvas, gaivus, vaisiškų ir kiek gėliškų apynių aromato",

    //     nutritionLabel : "Maistingumo<br>deklaracija",
    //     nutritionValue : "100 ML",

    //     caloriesLabel : "Energinė<br>vertė",
    //     caloriesValue : "103 KJ / 24 KCAL",

    //     ingredients : [
    //         [
    //             {label : "Riebalai" , value : "0 G"},
    //             {label : "Iš kurių sočiųjų riebalų rūgščių" , value : "0 G"}
    //         ],
    //         [
    //             {label : "Angliavandeniai" , value : "4,9 G"},
    //             {label : "Iš kurių cukrų" , value : "2,0 G"}
    //         ],
    //         [
    //             {label : "Baltymai" , value : "0,5 G"},
    //         ],
    //         [
    //             {label : "Druska" , value : "0 G"},
    //         ]
    //     ]
    // },

    {
        title : "BALTAS",
        subTitle : "",

        theme : "blue",

        video : "fest",

        description : "Vaisiško aromato gaivinantis nealkoholinis kvietinis alus",

        nutritionLabel : "Maistingumo<br>deklaracija",
        nutritionValue : "100 ML",

        caloriesLabel : "Energinė<br>vertė",
        caloriesValue : "92 kJ / 22 kcal",

        ingredients : [
            [
                {label : "Riebalai" , value : "0 G"},
                {label : "Iš kurių sočiųjų riebalų rūgščių" , value : "0 G"}
            ],
            [
                {label : "Angliavandeniai" , value : "4 G"},
                {label : "Iš kurių cukrų" , value : "1,1 G"}
            ],
            [
                {label : "Baltymai" , value : "0,5 G"},
            ],
            [
                {label : "Druska" , value : "0 G"},
            ]
        ]
    },

 
]
